import React, { useState } from 'react';
import LeftMenu from '../../components/Layout/LeftMenu';
import { isUserAdmin } from '../../utils';
import "./Logged.scss";

export function Logged(props) {
  const { viewForm, dataOptions, setDataOptions, dropdownOptions, setDropdownOptions, children } = props;

  return (
    <div className="logged-layout">
      <div className="logged-layout__content">
        <div className="logged-layout__left-menu">
          <LeftMenu viewForm={viewForm} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} />
        </div>
        <div className="logged-layout__children-content">
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}
