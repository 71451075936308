import React from "react";
import { Table } from "semantic-ui-react";
import { map } from 'lodash';

import "./IncomesR.scss";

export function IncomesR(props) {
  const { detailsIncomes } = props;

  return (
    <div className="div__IncomesR">
      <Table celled inverted selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Concepto</Table.HeaderCell>
            <Table.HeaderCell width={3}>Emisor</Table.HeaderCell>
            <Table.HeaderCell width={2}>Facturas</Table.HeaderCell>
            <Table.HeaderCell width={1}>Cuenta</Table.HeaderCell>
            <Table.HeaderCell width={1}>Divisa</Table.HeaderCell>
            <Table.HeaderCell width={1}>Monto</Table.HeaderCell>
            <Table.HeaderCell width={1}>T.C.</Table.HeaderCell>
            <Table.HeaderCell width={1}>MXN</Table.HeaderCell>
            <Table.HeaderCell width={1}>FECHA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(Object.keys(detailsIncomes), ((index) => (<>
            <Table.Row key={"IncomesR_" + String(index)}>
              <Table.Cell key={"IncomesR_Concepto_" + String(index)}>{detailsIncomes[index]["CONCEPTO"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Emisor_" + String(index)}>{detailsIncomes[index]["EMISOR"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Facturas_" + String(index)}>{detailsIncomes[index]["FACTURAS"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Cuenta_" + String(index)}>{detailsIncomes[index]["CUENTA"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Divisa_" + String(index)}>{detailsIncomes[index]["DIVISA"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Monto_" + String(index)} textAlign='right'>{detailsIncomes[index]["MONTO"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_TC_" + String(index)} textAlign='right'>{detailsIncomes[index]["TC"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_MXN_" + String(index)} textAlign='right'>{detailsIncomes[index]["MXN"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Fecha_" + String(index)} textAlign='right'>{detailsIncomes[index]["FECHA"] || ""}</Table.Cell>
            </Table.Row>
          </>)))}
        </Table.Body>
      </Table>
    </div>
  );
}