import React, { useState, useEffect } from "react";
import { Divider, Header, Icon, Dropdown } from "semantic-ui-react";
import { OutcomesCRUD, OutcomesR } from "../../components/Outcomes";
import { getDatabase, ref, onValue } from "firebase/database";
import { obtainStr, obtainUSDTCStr } from "../../utils/firebase";
import { map } from 'lodash'; // Is only in for arrays
import "./Outcomes.scss";

const db = getDatabase();
const optionsData = ref(db, '/options/referencias');

export function Outcomes(props) {
  const { viewForm } = props;
  const [optionSel, setOptionSel] = useState("")
  const [yearSel, setYearSel] = useState("");
  const [baseSearch, setBaseSearch] = useState({});
  const [yearSearch, setYearSearch] = useState([]);
  const [currYearSearch, setCurrYearSearch] = useState([]);
  const [detailsExtraOutcomes, setDetailsExtraOutcomes] = useState([]);

  useEffect(() => {
    onValue(optionsData, (snapshot) => {
      const currObj = snapshot.val();

      const years = [];
      const arrYearSearch = [];
      const objReferencesSearch = {};

      map(Object.keys(currObj), ((year) => {
        years.push(parseInt(year));
        arrYearSearch.push({ key: year, value: year, text: year });
        objReferencesSearch[year] = [];
        map(currObj[year], ((rf) => {
          objReferencesSearch[year].push(rf);
        }))
      }))
      setYearSearch(arrYearSearch); // Anios de busqueda
      setBaseSearch(objReferencesSearch); // Todas las referencias por anio
    })
  }, [])

  const yearOnChange = ((value) => {
    const optionsData = ref(db, '/outextra/' + value);
    onValue(optionsData, (snapshot) => {
      const currObj = snapshot.val();
      const auxOutcomes = [];
      for (let i = 0; i < currObj.length; i++) {
        auxOutcomes.push(Object.assign({}, currObj[i]))
        auxOutcomes[i]["MONTO"] = obtainStr(String(auxOutcomes[i]["MONTO"]));
        auxOutcomes[i]["TC"] = obtainUSDTCStr(String(auxOutcomes[i]["TC"]));
        auxOutcomes[i]["MXN"] = obtainStr(String(auxOutcomes[i]["MXN"]));
      }
      setDetailsExtraOutcomes(auxOutcomes);
    })
    setYearSel(value);
  });

  return (
    <div className="div__Outcomes">
      <h1>Egresos extras</h1>
      <Dropdown placeholder='Año' name="year" fluid search selection value={yearSel} options={yearSearch} noResultsMessage='No opción' color='black' onChange={(e, { value }) => { yearOnChange(value) }} />
      <Divider horizontal><Header as='h4'><Icon name='minus' />Egresos</Header></Divider>
      {(viewForm === 4 || viewForm === 2) && <>
        <OutcomesCRUD yearSel={yearSel} detailsExtraOutcomes={detailsExtraOutcomes} setDetailsExtraOutcomes={setDetailsExtraOutcomes} />
      </>}
      {(viewForm === 3) && <>
        <OutcomesR yearSel={yearSel} detailsExtraOutcomes={detailsExtraOutcomes} setDetailsExtraOutcomes={setDetailsExtraOutcomes} />
      </>}
    </div>
  );
}