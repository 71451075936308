import React, { useState } from "react";
import { Button, Dropdown, Divider, Header, Icon } from "semantic-ui-react";
import "./Reports.scss";

export function Reports(props) {
  const { dropdownOptions } = props;
  const [selectedYear, setSelectedYear] = useState(currentYear());

  const arrGlobalesOptions = [
    { key: 1, value: 1, text: "Operaciones" },
    { key: 2, value: 2, text: "Ingresos" },
    { key: 3, value: 3, text: "Egresos" }
  ]


  return (
    <div className="div__Reports">
      <h1>Generar reportes (Pendiente)</h1>
      <Dropdown placeholder='Año' fluid search selection value={selectedYear} options={dropdownOptions["years"]} noResultsMessage='No opción' onChange={(e, { value }) => { setSelectedYear(value) }} />

      <Divider horizontal><Header as='h4'><Icon name='world' />Globales</Header></Divider>
      <div className="div__Reports__block">
        <Dropdown placeholder='Tipo de Reporte' search selection options={arrGlobalesOptions} noResultsMessage='No opción' onChange={(e, { value }) => { console.log(value) }} />
        <div>
          <Button icon onClick={() => { console.log("GDrive") }}><Icon name="google drive" /> G Drive</Button>
          <Button icon onClick={() => { console.log("Folder") }}><Icon name='folder open' /> Folder</Button>
        </div>
      </div>

      <Divider horizontal><Header as='h4'><Icon name='table' />Detalles por operación</Header></Divider>
      <div className="div__Reports__block">
        <Dropdown placeholder='Referencia' search selection options={dropdownOptions["referencias"][selectedYear]} noResultsMessage='No opción' onChange={(e, { value }) => { console.log(value) }} />
        <div>
          <Button icon onClick={() => { console.log("GDrive") }}><Icon name="google drive" /> G Drive</Button>
          <Button icon onClick={() => { console.log("Folder") }}><Icon name='folder open' /> Folder</Button></div>
      </div>

      <Divider horizontal><Header as='h4'><Icon name='money bill alternate outline' />Presupuestos</Header></Divider>
      <div className="div__Reports__block">
        <Dropdown placeholder='Importacion / Exportación' search selection options={dropdownOptions["presupuestos"][selectedYear]} noResultsMessage='No opción' onChange={(e, { value }) => { console.log(value) }} />
        <div>
          <Button icon onClick={() => { console.log("GDrive") }}><Icon name="google drive" /> G Drive</Button>
          <Button icon onClick={() => { console.log("Folder") }}><Icon name='folder open' /> Folder</Button></div>
      </div>

      <Divider horizontal><Header as='h4'><Icon name='columns' />Comprobación de gastos</Header></Divider>
      <div className="div__Reports__block">
        <Dropdown placeholder='Importacion / Exportación' search selection options={dropdownOptions["presupuestos"][selectedYear]} noResultsMessage='No opción' onChange={(e, { value }) => { console.log(value) }} />
        <div>
          <Button icon onClick={() => { console.log("GDrive") }}><Icon name="google drive" /> G Drive</Button>
          <Button icon onClick={() => { console.log("Folder") }}><Icon name='folder open' /> Folder</Button>
        </div>
      </div>

      {/* <Divider horizontal><Header as='h4'><Icon name='trash' />Borrar toda la base de datos</Header></Divider>
      <Button onClick={() => {
        const updates = {};
        updates['/operations/'] = { algo: 'borrar' };
        return update(ref(db), updates);
      }}>
        Borrar Operaciones de la base de datos
      </Button> */}
    </div >
  );
}

function currentYear() {
  const currYear = new Date().getFullYear();
  return currYear;
}