import React from "react";
import { Dropdown, Button, Table, Divider, Header, Icon, Form, Input } from "semantic-ui-react";


export function ReportsButtons(props) {
  return (
    <div>
      <Button icon size='small' color='orange'> <Icon name="save" /> Guardar </Button>
      <Button.Group floated='right'>
        <Button icon color='red' size='small'>  <Icon name="delete" /> Eliminar</Button>
      </Button.Group>
    </div>
  );
}