import React, { useState, useEffect } from "react";
import { Divider, Header, Table, Icon, Dropdown, Button } from "semantic-ui-react";
import { getDatabase, ref, onValue } from "firebase/database";
import { map } from 'lodash'; // Is only in for arrays
import "./Options.scss";

const db = getDatabase();

export function Options(props) {
  const { viewForm } = props;
  const [optionSel, setOptionSel] = useState("")
  const [currOptions, setCurrOptions] = useState({});

  const optionsSearch = [
    { key: 1, value: "clientes", text: "CLIENTES" },
    { key: 2, value: "operaciones", text: "TIPOS DE OPERACION" },
    { key: 3, value: "lugares", text: "LUGARES DE ORIGEN Y DESTINO" },
    { key: 4, value: "aduanas", text: "ADUANAS" },
    { key: 5, value: "personas", text: "RECEPTORES Y EMISORES" },
    { key: 6, value: "conceptos", text: "CONCEPTOS" },
    { key: 7, value: "cuentas", text: "CUENTAS" },
    { key: 8, value: "divisas", text: "DIVISAS" }
  ]

  const changeOptionSel = ((value) => {
    setOptionSel(value);
    const optionsData = ref(db, '/options/' + value);
    onValue(optionsData, (snapshot) => {
      const currObj = snapshot.val();
      const objCurrOptions = {};
      for (let i = 0; i < currObj.length; i++) {
        objCurrOptions[i] = currObj[i];
      }
      setCurrOptions(objCurrOptions);
    })
  })


  return (
    <div className="div__Options">
      <h1>Editar las opciones</h1>
      <Dropdown placeholder='Opción' name="selectedOption" fluid search selection valus={optionSel} options={optionsSearch} noResultsMessage='No opción' color='black' onChange={(e, { value }) => { changeOptionSel(value) }} />
      <Divider horizontal><Header as='h4'><Icon name='table' />Opciones</Header></Divider>
      <Table celled inverted striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell collapsing>Editar</Table.HeaderCell>
            {(viewForm === 4) && <>
              <Table.HeaderCell collapsing>Borrar</Table.HeaderCell>
            </>}
          </Table.Row>
        </Table.Header>


        <Table.Body>
          {map(Object.keys(currOptions), ((index) => (<>
            <Table.Row key={"Options_" + String(index)}>
              <Table.Cell key={"Options_item_" + String(index)}>{currOptions[index]}</Table.Cell>
              <Table.Cell key={"Options_edit_" + String(index)}><Button icon color='yellow'><Icon name='pencil' /></Button></Table.Cell>
              {(viewForm === 4) && <>
                <Table.Cell key={"Options_del_" + String(index)}><Button icon color='red'><Icon name='trash' /></Button></Table.Cell>
              </>}
            </Table.Row>
          </>)))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.Cell colSpan='3'>
              <Button floated='right' icon size='small' color="blue"> <Icon name='add' /> Agregar </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}