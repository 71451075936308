import React, { useState, useEffect } from "react";
import { Form, Image, Dropdown } from "semantic-ui-react";
import "./NewOperationContent.scss";

export default function NewOperationContent(props) {
  const { onClose, dataOptions, setDataOptions, dropdownOptions, setDropdownOptions } = props;
  const [flagNewYear, setFlagNewYear] = useState(false);
  const [arrOperations, setArrOperations] = useState([]);
  const [operationForm, setOperationForm] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [baseReferencia, setBaseReferencia] = useState("");
  const [errorForm, setErrorForm] = useState(defaultErrorForm());
  const currYear = new Date().getFullYear();

  useEffect(() => {

    if (dataOptions["years"].includes(currYear)) {
      setBaseReferencia("NRGL-" + String(dataOptions["counters"][currYear]) + "-" + String(currYear).substring(2));
      setOperationForm({
        REFERENCIA: "NRGL-" + String(dataOptions["counters"][currYear]) + "-" + String(currYear).substring(2),
        OPERACION: "",
        CLIENTE: ""
      });
    } else {
      setFlagNewYear(true);
      console.log("Problema para arreglar en el 2023")
    }
  }, [dataOptions])

  const handleSubmit = () => {
    const errors = {};
    let formOk = true;
    setIsSubmitting(true);

    // Errores por refencia mal escrita
    if (operationForm.REFERENCIA.length === 11 || operationForm.REFERENCIA.length === 15) {
      if (operationForm.REFERENCIA.length === 11) { // Referencias simple
        if (operationForm.REFERENCIA !== baseReferencia) {
          errors.REFERENCIA = true;
          formOk = false;
        }
      } else { // Referencia multiple
        if (operationForm.REFERENCIA.substring(0, 9) === baseReferencia.substring(0, 8) + "_" && operationForm.REFERENCIA.substring(13) === String(currYear).substring(2)) {
          if (parseInt(operationForm.REFERENCIA.substring(9, 12)) <= dataOptions["counters"][currYear]) {
            errors.REFERENCIA = true;
            formOk = false;
          }
        } else {
          errors.REFERENCIA = true;
          formOk = false;
        }
      }
    } else {
      errors.REFERENCIA = true;
      formOk = false;
    }

    // Errores por tipo de operacion
    if (operationForm.OPERACION.length === 0) {
      errors.OPERACION = true;
      formOk = false;
    }

    // Error por cliente
    if (operationForm.CLIENTE.length === 0) {
      errors.CLIENTE = true;
      formOk = false;
    }

    if (formOk) {
      if (flagNewYear) {
        console.log('Por arreglar antes del 2023');
      } else {
        console.log(operationForm);
        setIsSubmitting(false);
        onClose();
      }
    } else {
      setErrorForm(errors);
    }
    setIsSubmitting(false);
  }

  // Por agregar en el futuro
  // const handleAddition = (e, { name, value }) => {
  //   console.log(name, value);
  // }

  const handleChange = (name, value) => {
    setOperationForm({
      ...operationForm,
      [name]: value
    });
  }

  const changeTypeOperation = (name, value) => {
    setArrOperations(value);
    setOperationForm({
      ...operationForm,
      [name]: value.join(' - ')
    });
  }

  return (
    <div className="div__NewOperationContent">
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Form.Input
            name="REFERENCIA"
            placeholder="Referencia de operación"
            value={operationForm.REFERENCIA || ""}
            onChange={(e, { name, value }) => { handleChange(name, value) }}
            error={errorForm.REFERENCIA}
          />
          {errorForm.REFERENCIA && (
            <span className="error-text">
              Por favor introduce una referencia válida.
            </span>
          )}
        </Form.Field>

        <Form.Field>
          <Dropdown
            name="OPERACION"
            placeholder='Tipo de operación'
            search multiple selection fluid
            // onAddItem={handleAddition}
            value={arrOperations || []}
            options={dropdownOptions["operaciones"]}
            onChange={(e, { name, value }) => { changeTypeOperation(name, value) }}
            error={errorForm.OPERACION}
          />
          {errorForm.OPERACION && (
            <span className="error-text">
              Por favor introduce el tipo de operacion.
            </span>
          )}
        </Form.Field>

        <Form.Field>
          <Dropdown
            name="CLIENTE"
            placeholder='Cliente'
            search selection fluid
            // onAddItem={handleAddition}
            value={operationForm.CLIENTE || ""}
            options={dropdownOptions["clientes"]}
            onChange={(e, { name, value }) => { handleChange(name, value) }}
            error={errorForm.CLIENTE}
          />
          {errorForm.CLIENTE && (
            <span className="error-text">
              Por favor introduce el cliente.
            </span>
          )}
        </Form.Field>

        <Form.Button type="submit" primary fluid loading={isSubmitting}>
          Crear operación
        </Form.Button>
      </Form>
    </div>
  );
}

function defaultErrorForm() {
  return {
    REFERENCIA: false,
    OPERACION: false,
    CLIENTE: false,
  };
}