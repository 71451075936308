import React, { useEffect, useState } from "react";
import { Divider, Header, Icon } from "semantic-ui-react";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  RefSearch, LineGraph, ReportsButtons,
  BudgetCRUD, BudgetR,
  DetailsCRUD, DetailsR,
  IncomesCRUD, IncomesR,
  OutcomesCRUD, OutcomesR
} from "../../components/References";
import { obtainStr, obtainUSDTCStr } from "../../utils/firebase";
import { map } from 'lodash'; // Is only in for arrays
import "./References.scss";

const db = getDatabase();

export function References(props) {
  const { viewForm, dataOptions, setDataOptions, dropdownOptions, setDropdownOptions } = props;

  const [selectedReference, setSelectedReference] = useState('');
  const [infoGraph, setInfoGraph] = useState(defaultInfoGraph());
  // incomes outcomes
  const [detailsIncomes, setDetailsIncomes] = useState([]);
  const [detailsOutcomes, setDetailsOutcomes] = useState([]);
  // budgets
  const [infoBudget, setInfoBudget] = useState(defatultInfoBudget());
  const [detailsBudget, setDetailsBudget] = useState([]);
  // Operation
  const [extraOperation, setExtraOperation] = useState({});
  const [infoOperation, setInfoOperation] = useState(defaultInfoOperation());
  const [detailsOperation, setDetailsOperation] = useState([]);

  useEffect(() => {
    if (0 < selectedReference.length) {
      const refsData = ref(db, '/operations/' + selectedReference);
      onValue(refsData, (snapshot) => {
        let fullData = snapshot.val();
        setInfoOperation({
          CLIENTE: fullData.info.CLIENTE ? fullData.info.CLIENTE : "",
          OPERACION: fullData.info.OPERACION ? fullData.info.OPERACION : "",
          ORIGEN: fullData.info.ORIGEN ? fullData.info.ORIGEN : "",
          DESTINO: fullData.info.DESTINO ? fullData.info.DESTINO : "",
          ADUANA: fullData.info.ADUANA ? fullData.info.ADUANA : "",
          CONCLUIDA: fullData.info.CONCLUIDA ? fullData.info.CONCLUIDA : false,
        });
        setExtraOperation({
          BALANCE: fullData.BALANCE ? obtainStr(String(fullData.BALANCE)) : "0.00",
          CONJUNTO: fullData.CONJUNTO,
          PRESUPUESTO: fullData.PRESUPUESTO
        })

        const auxDetails = []
        if ("details" in fullData) {
          for (let i = 0; i < fullData.details.length; i++) {
            auxDetails.push({
              REFERENCIA: fullData['details'][i].REFERENCIA ? fullData['details'][i].REFERENCIA : "",
              BULTOS: fullData['details'][i].BULTOS ? fullData['details'][i].BULTOS : "",
              PESO: fullData['details'][i].PESO ? obtainStr(String(fullData['details'][i].PESO)) : "0.00",
              INICIO: fullData['details'][i].INICIO ? fullData['details'][i].INICIO : "",
              SALIDA: fullData['details'][i].SALIDA ? fullData['details'][i].SALIDA : "",
              ARRIBO: fullData['details'][i].ARRIBO ? fullData['details'][i].ARRIBO : "",
              MOD: fullData['details'][i].MOD ? fullData['details'][i].MOD : "",
              ENTREGA: fullData['details'][i].ENTREGA ? fullData['details'][i].ENTREGA : "",
            })
          }
        }
        setDetailsOperation(auxDetails);

        const auxBudget = []
        if (fullData.PRESUPUESTO) {
          let auxInfoBudget = fullData['budget'];
          if (auxInfoBudget !== undefined) {
            setInfoBudget({
              ATENCION: auxInfoBudget['ATENCION'] ? auxInfoBudget['ATENCION'] : "",
              VALOR_MERCANCIA: auxInfoBudget['VALOR_MERCANCIA'] ? auxInfoBudget['VALOR_MERCANCIA'] : "",
              DIVISA: auxInfoBudget['DIVISA'] ? auxInfoBudget['DIVISA'] : "",
              TC_USD: auxInfoBudget['TC_USD'] ? auxInfoBudget['TC_USD'] : "",
              FME: auxInfoBudget['FME'] ? auxInfoBudget['FME'] : "",
              VALOR_USD: auxInfoBudget['VALOR_USD'] ? auxInfoBudget['VALOR_USD'] : "",
              TOTAL_USD: auxInfoBudget['TOTAL_USD'] ? auxInfoBudget['TOTAL_USD'] : "",
              VALOR_ADUANA: auxInfoBudget['VALOR_ADUANA'] ? auxInfoBudget['VALOR_ADUANA'] : "",

              FECHA: auxInfoBudget['FECHA'] ? auxInfoBudget['FECHA'] : "",
              ARRIBO: auxInfoBudget['ARRIBO'] ? auxInfoBudget['ARRIBO'] : "",
              MBL_MAWB: auxInfoBudget['MBL_MAWB'] ? auxInfoBudget['MBL_MAWB'] : "",
              FRACCION: auxInfoBudget['FRACCION'] ? auxInfoBudget['FRACCION'] : "",
              PESO: auxInfoBudget['PESO'] ? auxInfoBudget['PESO'] : "",
              BULTOS: auxInfoBudget['BULTOS'] ? auxInfoBudget['BULTOS'] : "",
              MERCANCIA: auxInfoBudget['MERCANCIA'] ? auxInfoBudget['MERCANCIA'] : "",
              PROVEEDOR: auxInfoBudget['PROVEEDOR'] ? auxInfoBudget['PROVEEDOR'] : "",

              NUMERO_PEDIMENTO: auxInfoBudget['NUMERO_PEDIMENTO'] ? auxInfoBudget['NUMERO_PEDIMENTO'] : "",
              NUMERO_FACTURA: auxInfoBudget['NUMERO_FACTURA'] ? auxInfoBudget['NUMERO_FACTURA'] : "",
              IGI: auxInfoBudget['IGI'] ? auxInfoBudget['IGI'] : "",
              DTA: auxInfoBudget['DTA'] ? auxInfoBudget['DTA'] : "",
              IVA: auxInfoBudget['IVA'] ? auxInfoBudget['IVA'] : "",
              PREVALIDACION: auxInfoBudget['PREVALIDACION'] ? auxInfoBudget['PREVALIDACION'] : "",
              CUOTA_COMP: auxInfoBudget['CUOTA_COMP'] ? auxInfoBudget['CUOTA_COMP'] : "",
              TOTAL_IMPUESTOS: auxInfoBudget['TOTAL_IMPUESTOS'] ? auxInfoBudget['TOTAL_IMPUESTOS'] : "",
            });

            if ("details" in auxInfoBudget) {
              for (let i = 0; i < auxInfoBudget["details"].length; i++) {
                // auxBudget.push(Object.assign({}, auxInfoBudget["details"][i]))
                auxBudget.push({
                  CONCEPTO: auxInfoBudget["details"][i]["CONCEPTO"],
                  PRECIO_USD: obtainStr(String(auxInfoBudget["details"][i]["PRECIO_USD"])),
                  PRECIO_MXN: obtainStr(String(auxInfoBudget["details"][i]["PRECIO_MXN"])),
                  IMPUESTOS: obtainStr(String(auxInfoBudget["details"][i]["IMPUESTOS"])),
                  PRESUPUESTO: obtainStr(String(auxInfoBudget["details"][i]["PRESUPUESTO"])),
                  GASTADO: obtainStr(String(auxInfoBudget["details"][i]["GASTADO"])),
                  POR_PAGAR: obtainStr(String(auxInfoBudget["details"][i]["POR_PAGAR"]))
                })
              }
            }
          } else {
            setInfoBudget(defatultInfoBudget());
          }
        }
        setDetailsBudget(auxBudget);

        const auxIncomes = [];
        if ("incomes" in fullData) {
          for (let i = 0; i < fullData.incomes.length; i++) {
            auxIncomes.push(Object.assign({}, fullData["incomes"][i]))
            auxIncomes[i]["MONTO"] = obtainStr(String(auxIncomes[i]["MONTO"]));
            auxIncomes[i]["TC"] = obtainUSDTCStr(String(auxIncomes[i]["TC"]));
            auxIncomes[i]["MXN"] = obtainStr(String(auxIncomes[i]["MXN"]));
          }
        }
        setDetailsIncomes(auxIncomes);

        const auxOutcomes = [];
        if ("outcomes" in fullData) {
          for (let i = 0; i < fullData.outcomes.length; i++) {
            auxOutcomes.push(Object.assign({}, fullData["outcomes"][i]))
            auxOutcomes[i]["MONTO"] = obtainStr(String(auxOutcomes[i]["MONTO"]));
            auxOutcomes[i]["TC"] = obtainUSDTCStr(String(auxOutcomes[i]["TC"]));
            auxOutcomes[i]["MXN"] = obtainStr(String(auxOutcomes[i]["MXN"]));
          }
        }
        setDetailsOutcomes(auxOutcomes);

        setInfoGraph({
          incomes: "incomes" in fullData ? fullData.incomes : [],
          outcomes: "outcomes" in fullData ? fullData.outcomes : []
        });
      });
    }
  }, [selectedReference]);

  return (
    <div className="div__References">
      <h1>Búsqueda por Referencia</h1>

      <RefSearch selectedReference={selectedReference} setSelectedReference={setSelectedReference} dropdownOptions={dropdownOptions} />

      <Divider horizontal><Header as='h4'><Icon name='newspaper' /> Datos operativos</Header></Divider>

      {(0 < selectedReference.length) && <>
        {(viewForm === 4 || viewForm === 3 || viewForm === 1) && <>
          <DetailsCRUD
            viewForm={viewForm} selectedReference={selectedReference} extraOperation={extraOperation}
            infoOperation={infoOperation} setInfoOperation={setInfoOperation}
            detailsOperation={detailsOperation} setDetailsOperation={setDetailsOperation}
            dataOptions={dataOptions} setDataOptions={setDataOptions}
            dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions}
          />
        </>}
        {(viewForm === 2) && <>
          <DetailsR
            infoOperation={infoOperation} detailsOperation={detailsOperation}
          />
        </>}

        {extraOperation.PRESUPUESTO && <>
          <Divider horizontal><Header as='h4'><Icon name='law' /> Presupuesto</Header></Divider>
          {(viewForm === 4 || viewForm === 3 || viewForm === 1) && <>
            <BudgetCRUD selectedReference={selectedReference}
              infoBudget={infoBudget} setInfoBudget={setInfoBudget}
              detailsBudget={detailsBudget} setDetailsBudget={setDetailsBudget}
            />
          </>}
          {(viewForm === 2) && <>
            <BudgetR selectedReference={selectedReference}
              infoBudget={infoBudget} detailsBudget={detailsBudget}
            />
          </>}
        </>}

        {(viewForm === 4 || viewForm === 3 || viewForm === 2) && <>
          <Divider horizontal><Header as='h4'><Icon name='add' /> Ingresos</Header></Divider>
          {(viewForm === 4 || viewForm === 2) ? <>
            <IncomesCRUD
              viewForm={viewForm} selectedReference={selectedReference}
              detailsIncomes={detailsIncomes} setDetailsIncomes={setDetailsIncomes}
              dataOptions={dataOptions} setDataOptions={setDataOptions}
              dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions}
            />
          </> : <>
            <IncomesR
              detailsIncomes={detailsIncomes}
            />
          </>}
        </>}

        {(viewForm === 4 || viewForm === 3 || viewForm === 2) && <>
          <Divider horizontal><Header as='h4'><Icon name='minus' />Egresos</Header></Divider>
          {(viewForm === 4 || viewForm === 2) ? <>
            <OutcomesCRUD
              viewForm={viewForm} selectedReference={selectedReference}
              detailsOutcomes={detailsOutcomes} setDetailsOutcomes={setDetailsOutcomes}
              dataOptions={dataOptions} setDataOptions={setDataOptions}
              dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions}
            />
          </> : <>
            <OutcomesR
              detailsOutcomes={detailsOutcomes}
            />
          </>}
        </>}

        {(viewForm === 4 || viewForm === 3) && <>
          <Divider horizontal><Header as='h4'><Icon name='line graph' /> Gráfica</Header></Divider>
          <LineGraph infoGraph={infoGraph} />
        </>}

        {(viewForm === 4) && <>
          <Divider horizontal><Header as='h4'><Icon name='line graph' /> Reportes</Header></Divider>
          <ReportsButtons />
        </>}
      </>}
    </div>
  );
}

function defaultInfoOperation() {
  return {
    CLIENTE: "",
    OPERACION: "",
    ORIGEN: "",
    DESTINO: "",
    ADUANA: "",
    CONCLUIDA: false,
    BALANCE: 0,
    CONJUNTO: false
  };
}

function defatultInfoBudget() {
  return {
    ATENCION: "",
    VALOR_MERCANCIA: "",
    DIVISA: "",
    TC_USD: "",
    FME: "",
    VALOR_USD: "",
    TOTAL_USD: "",
    VALOR_ADUANA: "",

    FECHA: "",
    ARRIBO: "",
    MBL_MAWB: "",
    FRACCION: "",
    PESO: "",
    BULTOS: "",
    MERCANCIA: "",
    PROVEEDOR: "",

    NUMERO_PEDIMENTO: "",
    NUMERO_FACTURA: "",
    IGI: "",
    DTA: "",
    IVA: "",
    PREVALIDACION: "",
    CUOTA_COMP: "",
    TOTAL_IMPUESTOS: "",
  };
}

function defaultInfoGraph() {
  return {
    incomes: {},
    outcomes: {},
  };
}