import React, { useEffect, useState } from "react";
import { Dropdown, Button, Divider, Icon, Form, Input } from "semantic-ui-react";
import { obtainStr } from "../../../utils/firebase";
import { map } from 'lodash';

import "./DetailsCRUD.scss";

export function DetailsCRUD(props) {
  const { viewForm, selectedReference, extraOperation, infoOperation, setInfoOperation, detailsOperation, setDetailsOperation, dataOptions, setDataOptions, dropdownOptions, setDropdownOptions } = props;
  const [formError, setFormError] = useState({});
  const [arrOperations, setArrOperations] = useState([]);

  // const { shell } = require('electron');

  useEffect(() => {
    if (infoOperation.OPERACION !== "" && infoOperation.OPERACION !== null) {
      setArrOperations(infoOperation.OPERACION.split(" - "));
    } else {
      setArrOperations([]);
    }
  }, [infoOperation])
  // const handleAddItem = (e, { name, value }) => {
  //   console.log(name, value);
  // }


  const changeInfoOperations = (name, value) => {
    setInfoOperation({
      ...infoOperation,
      [name]: value
    });
  }

  const changeTypeOperation = (name, value) => {
    setArrOperations(value);
    setInfoOperation({
      ...infoOperation,
      [name]: value.join(' - ')
    });
  }

  const changeDetailsOperation = (ind, name, value) => {
    let arr = detailsOperation[ind];
    arr[name] = value;
    setDetailsOperation({
      ...detailsOperation,
      [ind]: arr
    })
  }

  const falsetrue = [
    { key: true, text: 'SI', value: true },
    { key: false, text: 'NO', value: false },
  ]

  function openFolder() {
    let path = "G:\\Shared drives\\OPERACIONES NRG LOGISTICS\\2022\\DRALA TRADING\\NRGL-073_077 22";
    console.log(path);
  }

  return (
    <div className="div__DetailsCRUD">
      <Form>
        <Form.Group>
          <Form.Field width={5}>
            <label>CLIENTE</label>
            <Dropdown
              name="CLIENTE"
              placeholder='Cliente'
              search selection fluid
              value={infoOperation["CLIENTE"] || ''}
              options={dropdownOptions["clientes"]}
              // allowAdditions onAddItem={handleAddItem}
              onChange={(e, { name, value }) => { changeInfoOperations(name, value) }}
            />
          </Form.Field>
          <Form.Field width={15}>
            <label>TIPO DE OPERACION</label>
            <Dropdown
              name="OPERACION"
              placeholder='Tipo de operación'
              search multiple selection fluid
              value={arrOperations || []}
              options={dropdownOptions["operaciones"]}
              // allowAdditions onAddItem={handleAddItem}
              onChange={(e, { name, value }) => { changeTypeOperation(name, value) }}
            />
          </Form.Field>
          <Form.Field id="open_folder">
            <label>Link</label>
            <Button icon onClick={openFolder} ><Icon name='folder open' /></Button>
          </Form.Field>
        </Form.Group>

        {/* <Divider horizontal /> */}
        <div className="little_space" />

        <Form.Group>
          <Form.Field width={7}>
            <label>ORIGEN</label>
            <Dropdown
              name="ORIGEN"
              placeholder='Origen'
              search selection fluid
              value={infoOperation["ORIGEN"] || ''}
              options={dropdownOptions["lugares"]}
              // allowAdditions onAddItem={handleAddItem}
              onChange={(e, { name, value }) => { changeInfoOperations(name, value) }}
            />
          </Form.Field>
          <Form.Field width={7}>
            <label>DESTINO</label>
            <Dropdown
              placeholder='Destino'
              name="DESTINO"
              search selection fluid
              value={infoOperation["DESTINO"] || ''}
              options={dropdownOptions["lugares"]}
              // allowAdditions onAddItem={handleAddItem}
              onChange={(e, { name, value }) => { changeInfoOperations(name, value) }}
            />
          </Form.Field>
          <Form.Field width={7}>
            <label>ADUANA</label>
            <Dropdown
              placeholder='Aduana'
              name="ADUANA"
              search selection fluid
              value={infoOperation["ADUANA"] || ''}
              options={dropdownOptions["aduanas"]}
              // allowAdditions onAddItem={handleAddItem}
              onChange={(e, { name, value }) => { changeInfoOperations(name, value) }}
            />
          </Form.Field>
          {(viewForm === 4 || viewForm === 3) && <>
            <Form.Field width={2}>
              <label>CONCLUIDA</label>
              <Dropdown
                placeholder='Concluida'
                name="CONCLUIDA"
                search selection fluid
                value={infoOperation["CONCLUIDA"] || ''}
                options={falsetrue}
                // allowAdditions onAddItem={handleAddItem}
                onChange={(e, { name, value }) => { changeInfoOperations(name, value) }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <label>BALANCE - MXN</label>
              <Input
                placeholder='Balance'
                name='BALANCE'
                value={extraOperation["BALANCE"] || ""} id="money"
              />
            </Form.Field>
          </>}
        </Form.Group>

        <Divider horizontal />

        <Form.Group id="label_first_row">
          <Form.Field width={3}>
            <label>REFERENCIA</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>BULTOS</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>PESO [Kg]</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA INICIO</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA SALIDA</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA ARRIBO</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA MOD</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA ENTREGA</label>
          </Form.Field>
        </Form.Group>
        {map(Object.keys(detailsOperation), ((index) => (
          <Form.Group key={"detailsOperation_" + String(index)}>
            <Form.Field key={"detailsOperation_Referencia_" + String(index)} width={3}>
              <Input
                name='REFERENCIA'
                placeholder='Referencia'
                value={detailsOperation[index]["REFERENCIA"] || ''}
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Bultos_" + String(index)} width={3}>
              <Input
                name='BULTOS'
                placeholder='Bultos'
                value={detailsOperation[index]["BULTOS"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Peso_" + String(index)} width={3}>
              <Input
                name='PESO'
                placeholder='Peso'
                value={detailsOperation[index]["PESO"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
                id="money"
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Inicio_" + String(index)} width={3}>
              <Input
                name='INICIO'
                placeholder='Fecha Inicio'
                value={detailsOperation[index]["INICIO"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
                id="date"
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Salida_" + String(index)} width={3}>
              <Input
                name='SALIDA'
                placeholder='Fecha Salida'
                value={detailsOperation[index]["SALIDA"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
                id="date"
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Arribo_" + String(index)} width={3}>
              <Input
                name='ARRIBO'
                placeholder='Fecha Arribo'
                value={detailsOperation[index]["ARRIBO"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
                id="date"
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Mod_" + String(index)} width={3}>
              <Input
                name='MOD'
                placeholder='Fecha Mod'
                value={detailsOperation[index]["MOD"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
                id="date"
              />
            </Form.Field>
            <Form.Field key={"detailsOperation_Entrega_" + String(index)} width={3}>
              <Input
                name='ENTREGA'
                placeholder='Entrega'
                value={detailsOperation[index]["ENTREGA"] || ''}
                onChange={(e, { name, value }) => { changeDetailsOperation(index, name, value) }}
                id="date"
              />
            </Form.Field>
          </Form.Group>
        )))}
        <Button icon size='small' color='orange' onClick={() => { console.log(infoOperation, detailsOperation) }}> <Icon name="save" /> Guardar </Button>
      </Form>
    </div >
  );
}