import React from "react";
import { Dropdown, Button, Divider, Icon, Form, Input, Table } from "semantic-ui-react";
import { obtainStr } from "../../../utils/firebase";
import { map } from 'lodash';

import "./DetailsR.scss";

export function DetailsR(props) {
  const { infoOperation, detailsOperation } = props;

  return (
    <div className="div__DetailsR">
      <Table celled inverted compact >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Cliente</Table.HeaderCell>
            <Table.HeaderCell width={4}>Operacion</Table.HeaderCell>
            <Table.HeaderCell width={3}>Origen</Table.HeaderCell>
            <Table.HeaderCell width={3}>Destino</Table.HeaderCell>
            <Table.HeaderCell width={3}>Aduana</Table.HeaderCell>
            <Table.HeaderCell width={1}>Folder</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{infoOperation["CLIENTE"] || ""}</Table.Cell>
            <Table.Cell>{infoOperation["OPERACION"] || ""}</Table.Cell>
            <Table.Cell>{infoOperation["ORIGEN"] || ""}</Table.Cell>
            <Table.Cell>{infoOperation["DESTINO"] || ""}</Table.Cell>
            <Table.Cell>{infoOperation["ADUANA"] || ""}</Table.Cell>
            <Table.Cell><Button icon><Icon name="open folder" /></Button></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Divider horizontal />

      <Table celled inverted selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Referencia</Table.HeaderCell>
            <Table.HeaderCell width={1}>Bultos</Table.HeaderCell>
            <Table.HeaderCell width={1}>Peso [Kg]</Table.HeaderCell>
            <Table.HeaderCell width={1}>Fecha Inicio</Table.HeaderCell>
            <Table.HeaderCell width={1}>Fecha Salida</Table.HeaderCell>
            <Table.HeaderCell width={1}>Fecha Arribo</Table.HeaderCell>
            <Table.HeaderCell width={1}>Fecha Mod</Table.HeaderCell>
            <Table.HeaderCell width={1}>Fecha Entrega</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(Object.keys(detailsOperation), ((index) => (<>
            <Table.Row key={"IncomesR_" + String(index)}>
              <Table.Cell key={"IncomesR_Referencia_" + String(index)}>{detailsOperation[index]["REFERENCIA"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Bultos_" + String(index)}>{detailsOperation[index]["BULTOS"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Peso_" + String(index)} textAlign='right'>{detailsOperation[index]["PESO"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Inicio_" + String(index)} textAlign='right'>{detailsOperation[index]["INICIO"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Salida_" + String(index)} textAlign='right'>{detailsOperation[index]["SALIDA"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Arribo_" + String(index)} textAlign='right'>{detailsOperation[index]["ARRIBO"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Mod_" + String(index)} textAlign='right'>{detailsOperation[index]["MOD"] || ""}</Table.Cell>
              <Table.Cell key={"IncomesR_Entrega_" + String(index)} textAlign='right'>{detailsOperation[index]["ENTREGA"] || ""}</Table.Cell>
            </Table.Row>
          </>)))}
        </Table.Body>
      </Table>
    </div >
  );
}