import React, { useState } from "react";
import { Dropdown, Button, Icon, Form, Input } from "semantic-ui-react";
import { AcceptModal } from "../../Shared";
import { map } from 'lodash';

import "./IncomesCRUD.scss";

export function IncomesCRUD(props) {
  const { viewForm, selectedReference, detailsIncomes, setDetailsIncomes, dataOptions, setDataOptions, dropdownOptions, setDropdownOptions } = props;

  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   console.log("IncomesCRUD : ", infoIncomes)
  // }, [infoIncomes])
  // const handleAddItem = (e, { name, value }) => {
  //   console.log(name, value);
  // }

  const changeDetailsIncomes = (index, name, value) => {
    let arr = detailsIncomes[index];
    arr[name] = value;
    setDetailsIncomes({
      ...detailsIncomes,
      [index]: arr
    })
  }

  const handleSubmit = () => {
    console.log(selectedReference, detailsIncomes);
  }

  const handleAddition = () => {
    console.log(detailsIncomes);
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (index) => {
    console.log("borrar : ", index)
    setShowModal(true);
  };

  return (
    <div className="div__IncomesCRUD">
      <Form>
        <Form.Group id="label_first_row">
          <Form.Field width={6}>
            <label>CONCEPTO</label>
          </Form.Field>
          <Form.Field width={10}>
            <label>EMISOR</label>
          </Form.Field>
          <Form.Field width={4}>
            <label>FACTURAS</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>CUENTA</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>DIVISA</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>MONTO</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>T.C.</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>MXN</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA</label>
          </Form.Field>
          {(viewForm === 4) && <>
            <Form.Field width={1}>
              <label>DEL</label>
            </Form.Field>
          </>}
        </Form.Group>
        {map(Object.keys(detailsIncomes), ((index) => (
          <Form.Group key={"detailsIncomes_" + String(index)}>
            <Form.Field key={"detailsIncomes_Concepto_" + String(index)} width={6}>
              <Dropdown
                name="CONCEPTO"
                placeholder='Concepto'
                search selection fluid
                value={detailsIncomes[index]["CONCEPTO"] || ""}
                options={dropdownOptions["conceptos"]}
                // allowAdditions onAddItem={handleAddItem}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsIncomes_Emisor_" + String(index)} width={10}>
              <Dropdown
                name="EMISOR"
                placeholder='Emisor'
                search selection fluid
                value={detailsIncomes[index]["EMISOR"] || ""}
                options={dropdownOptions["personas"]}
                // allowAdditions onAddItem={handleAddItem}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }} />
            </Form.Field>
            <Form.Field key={"detailsIncomes_Facturas_" + String(index)} width={4}>
              <Input
                name="FACTURAS"
                placeholder='Facturas'
                value={detailsIncomes[index]["FACTURAS"] || ""}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }} />
            </Form.Field>
            <Form.Field key={"detailsIncomes_Cuenta_" + String(index)} width={3}>
              <Dropdown
                name="CUENTA"
                placeholder='Cuenta'
                search selection fluid
                value={detailsIncomes[index]["CUENTA"] || ""}
                options={dropdownOptions["cuentas"]}
                // allowAdditions onAddItem={handleAddItem}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsIncomes_Divisa_" + String(index)} width={2}>
              <Dropdown
                name="DIVISA"
                placeholder='Divisa'
                search selection fluid
                value={detailsIncomes[index]["DIVISA"] || ""}
                options={dropdownOptions["divisas"]}
                // allowAdditions onAddItem={handleAddItem}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }} />
            </Form.Field>
            <Form.Field key={"detailsIncomes_Monto_" + String(index)} width={3}>
              <Input
                name="MONTO"
                placeholder='Monto'
                value={detailsIncomes[index]["MONTO"] || ""}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }}
                id="money"
              />
            </Form.Field>
            <Form.Field key={"detailsIncomes_TC_" + String(index)} width={2}>
              <Input
                name="TC"
                placeholder='T.C.'
                value={detailsIncomes[index]["TC"] || ""}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }}
                id="money"
              />
            </Form.Field>
            <Form.Field key={"detailsIncomes_MXN_" + String(index)} width={3}>
              <Input
                name="MXN"
                placeholder='MXN'
                value={detailsIncomes[index]["MXN"] || ""}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }}
                id="money"
              />
            </Form.Field>
            <Form.Field key={"detailsIncomes_Fecha_" + String(index)} width={3}>
              <Input
                name="FECHA"
                placeholder='Fecha'
                value={detailsIncomes[index]["FECHA"] || ""}
                onChange={(e, { name, value }) => { changeDetailsIncomes(index, name, value) }}
                id="date"
              />
            </Form.Field>
            {(viewForm === 4) && <>
              <Form.Field key={"detailsIncomes_Borrar_" + String(index)} width={1}>
                <Button icon color='red' onClick={() => { openModal(index) }}> <Icon name="delete" /> </Button>
              </Form.Field>
            </>}
          </Form.Group>
        )))}
        <Button icon size='small' color='orange' onClick={() => { handleSubmit() }}> <Icon name="save" /> Guardar </Button>
        <Button floated='right' icon size='small' color="blue" onClick={() => { handleAddition() }}> <Icon name='add' /> Agregar </Button>
      </Form>
      <AcceptModal
        show={showModal}
        onClose={closeModal}
      />
    </div>
  );
}