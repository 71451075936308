import React from 'react';
import { NRGLogo } from "../../assets";

import "./Home.scss";

export function Home() {
  return (
    <div className="NRG-Home">
      <img src={NRGLogo} alt="NRG" />
    </div>
  )
}