import React from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import "./AcceptModal.scss";

export function AcceptModal(props) {
  const { show, onClose } = props;

  return (
    <Modal open={show} size='tiny' onClose={onClose} className="accept-modal">
      <Modal.Header>
        <div />
        <span>¿Estás seguro?</span>
        <Icon name="close" onClick={onClose} link />
      </Modal.Header>
      <Modal.Content>
        <Button icon size='small' color="black"><Icon name="cancel" /> Cancelar</Button>
        <Button floated='right' icon size='small' color="red"><Icon name="trash" /> Eliminar</Button>
      </Modal.Content>
    </Modal>
  );
}