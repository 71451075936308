import React, { useState } from "react";
import { Menu, Icon, Image } from "semantic-ui-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BasicModal } from "../../Shared";
import { Auth, User } from "../../../api";
import NewOperationContent from "../../Operations/NewOperationContent";
// import { AddIncomeForm } from "../../Income";
// import { AddOutcomeForm } from "../../Outcome";
import "./LeftMenu.scss";

const auth = new Auth();
const user = new User();

export default function LeftMenu(props) {
  const { viewForm, dataOptions, setDataOptions, dropdownOptions, setDropdownOptions } = props;

  const { pathname } = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState(null);

  const isCurrentPage = (route) => {
    return route === pathname;
  };

  const closeModal = () => {
    setShowModal(false);
    setTitleModal("");
    setContentModal(null);
  };

  const openModal = (type) => {
    if (type === "operation") {
      setTitleModal("Nueva operacion");
      setContentModal(<NewOperationContent onClose={closeModal} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} />);
    }
    // if (type === "income") {
    //   setTitleModal("Nuevo ingreso");
    //   setContentModal(<AddIncomeForm onClose={closeModal} />);
    // }
    // if (type === "outcome") {
    //   setTitleModal("Nuevo egreso");
    //   setContentModal(<AddOutcomeForm onClose={closeModal} />);
    // }

    setShowModal(true);
  };

  // topbar
  const navigation = useNavigate();
  const userData = user.getMe();

  const displayName = userData.displayName || "Mi cuenta";

  const goBack = () => {
    navigation(-1);
  };
  // topbar

  return (
    <div className="div__LeftMenu">
      <div className="left-menu-top">
        <div className="top-left"><Icon name="power" onClick={auth.logout} link /></div>
        <div className="top-mid"><Link to="/profile"><span>{displayName}</span></Link></div>
        <div className="top-right"><Icon name="angle left" className="left-menu-top__back" link onClick={goBack} /></div>
      </div>

      <div className="left-menu-mid">
        <Menu secondary vertical fluid>
          <Menu.Item
            as={Link}
            to="/"
            name="Inicio"
            icon="home"
            active={isCurrentPage("/")}
          />
          <Menu.Item
            as={Link}
            to="/references"
            name="Buscar Referencia"
            icon="search"
            active={isCurrentPage("/references")}
          />
          <Menu.Item
            as={Link}
            to="/operations"
            name="Operaciones"
            icon="window maximize outline"
            active={isCurrentPage("/operations")}
          />
          {(viewForm === 4 || viewForm === 2) && <>
            <Menu.Item
              as={Link}
              to="/incomes"
              name="Ingresos extras"
              icon="add square"
              active={isCurrentPage("/incomes")}
            />
            <Menu.Item
              as={Link}
              to="/outcomes"
              name="Egresos extras"
              icon="minus square"
              active={isCurrentPage("/outcomes")}
            />
          </>}
          {(viewForm === 4 || viewForm === 3) && <>
            <Menu.Item
              as={Link}
              to="/options"
              name="Editar opciones"
              icon="sort"
              active={isCurrentPage("/options")}
            />
            {(viewForm === 4) && <>
              <Menu.Item
                as={Link}
                to="/globalbalance"
                name="Balance actual"
                icon="calendar check"
                active={isCurrentPage("/globalbalance")}
              />
              <Menu.Item
                as={Link}
                to="/reports"
                name="Generar reportes"
                icon="file alternate"
                active={isCurrentPage("/reports")}
              />
            </>}
          </>}
        </Menu>
      </div>

      <div className="left-menu-bottom">
        <Menu secondary vertical fluid>
          {(viewForm === 4 || viewForm === 3 || viewForm === 1) && <>
            <Menu.Item
              name="Nueva operacion"
              icon="plus"
              link
              onClick={() => openModal("operation")}
            />
          </>}
          {(viewForm === 4 || viewForm === 2) && <>
            <Menu.Item
              name="Nuevo ingreso"
              icon="plus"
              link
              onClick={() => openModal("income")}
            />
            <Menu.Item
              name="Nuevo egreso"
              icon="plus"
              link
              onClick={() => openModal("outcome")}
            />
          </>}
        </Menu>
      </div>

      <BasicModal
        show={showModal}
        onClose={closeModal}
        title={titleModal}
        children={contentModal}
      />
    </div>
  );
}