import React, { useState } from "react";
import { Dropdown, Confirm, Button, Table, Divider, Header, Icon, Form, Input, Grid } from "semantic-ui-react";
import { AcceptModal } from "../../Shared";
import { map, isEqual } from 'lodash';
import AcceptForm from "../../Shared/AcceptForm";
import "./BudgetR.scss";

export function BudgetR(props) {
  const { selectedReference, infoBudget, detailsBudget } = props;
  return (
    <div className="div__IncomesR">
      <Grid columns='equal' divided inverted padded>
        <Grid.Row textAlign='center'>
          <Grid.Column>
            <Table definition inverted columns='10'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing textAlign='right'>Atención</Table.Cell>
                  <Table.Cell>{infoBudget.ATENCION || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Valor de la mercancia</Table.Cell>
                  <Table.Cell>{infoBudget.VALOR_MERCANCIA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Divisa</Table.Cell>
                  <Table.Cell>{infoBudget.DIVISA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>T.C. USD</Table.Cell>
                  <Table.Cell>{infoBudget.TC_USD || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>F.M.E.</Table.Cell>
                  <Table.Cell>{infoBudget.FME || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Valor USD</Table.Cell>
                  <Table.Cell>{infoBudget.VALOR_USD || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Total valor USD</Table.Cell>
                  <Table.Cell>{infoBudget.TOTAL_USD || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Valor en Aduana</Table.Cell>
                  <Table.Cell>{infoBudget.VALOR_ADUANA || ""}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column>
            <Table definition inverted columns='10'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing textAlign='right'>Fecha</Table.Cell>
                  <Table.Cell>{infoBudget.FECHA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Fecha arribo</Table.Cell>
                  <Table.Cell>{infoBudget.ARRIBO || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>MBL/MAWB</Table.Cell>
                  <Table.Cell>{infoBudget.MBL_MAWB || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Fracción</Table.Cell>
                  <Table.Cell>{infoBudget.FRACCION || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Peso</Table.Cell>
                  <Table.Cell>{infoBudget.PESO || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Bultos</Table.Cell>
                  <Table.Cell>{infoBudget.BULTOS || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Mercancía</Table.Cell>
                  <Table.Cell>{infoBudget.MERCANCIA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Proveedor</Table.Cell>
                  <Table.Cell>{infoBudget.PROVEEDOR || ""}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column>
            <Table definition inverted columns='10'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing textAlign='right'>Número de pedimento</Table.Cell>
                  <Table.Cell>{infoBudget.NUMERO_PEDIMENTO || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Número de factura</Table.Cell>
                  <Table.Cell>{infoBudget.NUMERO_FACTURA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>IGI</Table.Cell>
                  <Table.Cell>{infoBudget.IGI || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>DTA</Table.Cell>
                  <Table.Cell>{infoBudget.DTA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>I.V.A.</Table.Cell>
                  <Table.Cell>{infoBudget.IVA || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Prevalidación</Table.Cell>
                  <Table.Cell>{infoBudget.PREVALIDACION || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Cuota compuesta</Table.Cell>
                  <Table.Cell>{infoBudget.CUOTA_COMP || ""}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='right'>Total de impuestos</Table.Cell>
                  <Table.Cell>{infoBudget.TOTAL_IMPUESTOS || ""}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider horizontal>
        <Header as='h5'>
          Gastos
        </Header>
      </Divider>

      <Table celled inverted selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Concepto</Table.HeaderCell>
            <Table.HeaderCell width={2}>Precio USD</Table.HeaderCell>
            <Table.HeaderCell width={2}>Precio MXN</Table.HeaderCell>
            <Table.HeaderCell width={2}>Impuestos</Table.HeaderCell>
            <Table.HeaderCell width={2}>Presupuesto</Table.HeaderCell>
            <Table.HeaderCell width={2}>Gastado</Table.HeaderCell>
            <Table.HeaderCell width={2}>Por pagar</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(Object.keys(detailsBudget), ((index) => (<>
            <Table.Row key={"detailsBudget" + String(index)}>
              <Table.Cell key={"detailsBudget_Concepto_" + String(index)}>{detailsBudget[index]["CONCEPTO"] || ""}</Table.Cell>
              <Table.Cell key={"detailsBudget_Precio_USD_" + String(index)} textAlign='right'>{detailsBudget[index]["PRECIO_USD"] || ""}</Table.Cell>
              <Table.Cell key={"detailsBudget_Precio_MXN_" + String(index)} textAlign='right'>{detailsBudget[index]["PRECIO_MXN"] || ""}</Table.Cell>
              <Table.Cell key={"detailsBudget_Impuestos_" + String(index)} textAlign='right'>{detailsBudget[index]["IMPUESTOS"] || ""}</Table.Cell>
              <Table.Cell key={"detailsBudget_Presupuesto_" + String(index)} textAlign='right'>{detailsBudget[index]["PRESUPUESTO"] || ""}</Table.Cell>
              <Table.Cell key={"detailsBudget_Gastado_" + String(index)} textAlign='right'>{detailsBudget[index]["GASTADO"] || ""}</Table.Cell>
              <Table.Cell key={"detailsBudget_Por_Pagar_" + String(index)} textAlign='right'>{detailsBudget[index]["POR_PAGAR"] || ""}</Table.Cell>
            </Table.Row>
          </>)))}
        </Table.Body>
      </Table>
    </div>
  );
}