import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Profile, References, GlobalBalance, Outcomes, Incomes, Operations, Options, Reports } from "../pages/";
import { isUserAdmin } from "../utils/firebase";
import { Logged } from "../layouts";
import { map } from 'lodash'; // Is only in for arrays

const db = getDatabase();
const optionsReference = ref(db, '/options');

export function PagesRouter(props) {
  const { user } = props;
  const [viewForm, setViewForm] = useState(0);
  const [userAdmin, setUserAdmin] = useState(false);
  const [dataOptions, setDataOptions] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    isUserAdmin(user.uid).then((response) => {
      setUserAdmin(response);
      if (response.type === "superadmin") {
        setViewForm(4);
      } else if (response.type === "admin") {
        setViewForm(3);
      } else if (response.type === "accountant") {
        setViewForm(2);
      } else if (response.type === "worker") {
        setViewForm(1);
      }
    })

    onValue(optionsReference, (snapshot) => {
      const vals = snapshot.val();
      let objDropdownOptions = {};
      map(Object.keys(vals), ((objkey) => {
        if (objkey === "presupuestos" || objkey === "referencias") {
          objDropdownOptions[objkey] = {}
          map(Object.keys(vals[objkey]), ((year) => {
            objDropdownOptions[objkey][year] = []
            map(vals[objkey][year], ((rf) => {
              objDropdownOptions[objkey][year].push({ key: rf, value: rf, text: rf });
            }))
          }))
        } else if (objkey !== "counters") {
          objDropdownOptions[objkey] = []
          map(vals[objkey], ((rf) => {
            objDropdownOptions[objkey].push({ key: rf, value: rf, text: rf });
          }))
        }
      }))
      setDataOptions(vals);
      setDropdownOptions(objDropdownOptions);
    });
  }, [user])

  return (
    <div className="div__PagesRouter">
      <Router>
        <Logged userAdmin={userAdmin} viewForm={viewForm} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile userAdmin={userAdmin} viewForm={viewForm} setViewForm={setViewForm} />} />
            <Route path="/references" element={<References viewForm={viewForm} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} />} />
            <Route path="/operations" element={<Operations viewForm={viewForm} dataOptions={dataOptions} dropdownOptions={dropdownOptions} />} />
            <Route path="/outcomes" element={<Outcomes viewForm={viewForm} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} />} />
            <Route path="/incomes" element={<Incomes viewForm={viewForm} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} />} />
            <Route path="/globalbalance" element={<GlobalBalance dataOptions={dataOptions} dropdownOptions={dropdownOptions} />} />
            <Route path="/options" element={<Options viewForm={viewForm} dataOptions={dataOptions} setDataOptions={setDataOptions} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} />} />
            <Route path="/reports" element={<Reports viewForm={viewForm} dropdownOptions={dropdownOptions} />} />
          </Routes>
        </Logged>
      </Router>
    </div>
  );
}