import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { map } from 'lodash'; // Is only in for arrays

import "./GraphBalance.scss";

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export function GraphBalance(props) {
  const { infoGraphLine, infoGraphZero } = props;
  const [graphData, setGraphData] = useState(defaultGraphData());


  useEffect(() => {
    setGraphData({
      datasets: [
        {
          data: infoGraphLine,
          label: "Mis datos",
          tension: 0.3,
          borderColor: "rgb(233, 83, 30)",
          pointRadius: 6,
          pointBackgroundColor: "rgb(233, 83, 30)",
          backgroundColor: "rgba(233, 83, 30, 0.3)",
        },
        {
          data: infoGraphZero,
          label: "Cero",
          tension: 0.3,
          borderColor: "rgb(200, 200, 200)",
          pointRadius: 0,
          pointBackgroundColor: "rgb(200, 200, 200)",
          backgroundColor: "rgba(200, 200, 200, 0.3)",
        }
      ]
    });

  }, [infoGraphLine, infoGraphZero])

  const options = {
    response: true,
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: 'YYYY-MM-DD',
          unit: 'day'
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        title: {
          display: true,
          text: 'value'
        }
      }
    },
  };

  return (
    <div className="div__LineGraph">
      <Line options={options} data={graphData} height={200} width={600} />
    </div>
  );
}

function defaultGraphData() {
  return {
    datasets: [
      {
        data: [{ x: '2021-01-01', y: 0 }, { x: '2021-12-31', y: 0 }],
        label: "Mis datos",
        tension: 0.3,
        borderColor: "rgb(233, 83, 30)",
        pointRadius: 6,
        pointBackgroundColor: "rgb(233, 83, 30)",
        backgroundColor: "rgba(233, 83, 30, 0.3)",
      }
    ]
  };
}