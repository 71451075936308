import React, { useState, useEffect } from 'react';
import { Divider, Header, Table, Icon, Dropdown } from "semantic-ui-react";
import { map } from 'lodash'; // Is only in for arrays
import "./IncomesR.scss";

export function IncomesR(props) {
  const { detailsExtraIncomes } = props;

  return (
    <div className="div__IncomesR">
      <Table celled inverted selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Concepto</Table.HeaderCell>
            <Table.HeaderCell width={3}>Emisor</Table.HeaderCell>
            <Table.HeaderCell width={2}>Facturas</Table.HeaderCell>
            <Table.HeaderCell width={1}>Cuenta</Table.HeaderCell>
            <Table.HeaderCell width={1}>Divisa</Table.HeaderCell>
            <Table.HeaderCell width={1}>Monto</Table.HeaderCell>
            <Table.HeaderCell width={1}>T.C.</Table.HeaderCell>
            <Table.HeaderCell width={1}>MXN</Table.HeaderCell>
            <Table.HeaderCell width={1}>FECHA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(Object.keys(detailsExtraIncomes), ((index) => (<>
            <Table.Row key={"ExtraIncomesR" + String(index)}>
              <Table.Cell>{detailsExtraIncomes[index]["CONCEPTO"] || ""}</Table.Cell>
              <Table.Cell>{detailsExtraIncomes[index]["EMISOR"] || ""}</Table.Cell>
              <Table.Cell>{detailsExtraIncomes[index]["FACTURAS"] || ""}</Table.Cell>
              <Table.Cell>{detailsExtraIncomes[index]["CUENTA"] || ""}</Table.Cell>
              <Table.Cell>{detailsExtraIncomes[index]["DIVISA"] || ""}</Table.Cell>
              <Table.Cell textAlign='right'>{detailsExtraIncomes[index]["MONTO"] || ""}</Table.Cell>
              <Table.Cell textAlign='right'>{detailsExtraIncomes[index]["TC"] || ""}</Table.Cell>
              <Table.Cell textAlign='right'>{detailsExtraIncomes[index]["MXN"] || ""}</Table.Cell>
              <Table.Cell textAlign='right'>{detailsExtraIncomes[index]["FECHA"] || ""}</Table.Cell>
            </Table.Row>
          </>)))}
        </Table.Body>
      </Table>
    </div>
  );
}