import React, { useState } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { AvatarUpdate, DisplayNameUpdateForm, EmailUpdateForm, PasswordUpdateForm } from "../../components/Profile";
import { BasicModal } from "../../components/Shared";
import { User } from "../../api";
import "./Profile.scss";

const userController = new User();

export function Profile(props) {
  const { userAdmin, viewForm, setViewForm } = props;
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState(null);

  const { displayName } = userController.getMe();

  const onCloseModal = () => {
    setShowModal(false);
    setTitleModal("");
    setContentModal(null);
  };

  const openForm = (type) => {
    if (type === "displayName") {
      setTitleModal("Actualizar nombre y apellidos");
      setContentModal(<DisplayNameUpdateForm onClose={onCloseModal} />);
    }

    if (type === "password") {
      setTitleModal("Actualizar contraseña");
      setContentModal(<PasswordUpdateForm onClose={onCloseModal} />);
    }

    setShowModal(true);
  };

  const userTypes = [
    { key: 4, text: 'SuperAdmin', value: 4 },
    { key: 3, text: 'Julio', value: 3 },
    { key: 2, text: 'Contador', value: 2 },
    { key: 1, text: 'Trabajador', value: 1 },
  ]

  return (
    <>
      <div className="div__Profile">
        <h1>Configuración</h1>

        <div className="div__Profile__block">
          <span>Nombre: {displayName}</span>
          <Button onClick={() => openForm("displayName")}>Actualizar</Button>
        </div>

        <div className="div__Profile__block">
          <span>Contraseña: ••••••••••••</span>
          <Button onClick={() => openForm("password")}>Actualizar</Button>
        </div>
        {userAdmin.type === "superadmin" &&
          <div className="div__Profile__block">
            <span>Cambiar vista</span>
            <Dropdown placeholder='Usuario' search selection value={viewForm} options={userTypes} onChange={(e, { value }) => { setViewForm(value) }} />
          </div>}
      </div>

      <BasicModal
        show={showModal}
        onClose={onCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}