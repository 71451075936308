import React, { useState, useEffect } from 'react';
import { Divider, Header, Table, Icon, Dropdown, Form, Input, Button } from "semantic-ui-react";
import { AcceptModal } from "../../Shared";
import { map } from 'lodash'; // Is only in for arrays
import "./OutcomesCRUD.scss";

export function OutcomesCRUD(props) {
  const { detailsExtraOutcomes, setDetailsExtraOutcomes } = props;
  const [showModal, setShowModal] = useState(false);

  const changeDetailsExtraOutcomes = (index, name, value) => {
    let arr = detailsExtraOutcomes[index];
    arr[name] = value;
    setDetailsExtraOutcomes({
      ...detailsExtraOutcomes,
      [index]: arr
    })
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (index) => {
    console.log("borrar : ", index)
    setShowModal(true);
  };

  const arrCuentas = [
    { key: "AMEX", value: "AMEX", text: "AMEX" },
    { key: "BBVA", value: "BBVA", text: "BBVA" },
    { key: "INTERCAM", value: "INTERCAM", text: "INTERCAM" },
    { key: "MONEX", value: "MONEX", text: "MONEX" }
  ]

  const arrDivisas = [
    { key: "EUR", value: "EUR", text: "EUR" },
    { key: "MXN", value: "MXN", text: "MXN" },
    { key: "USD", value: "USD", text: "USD" }
  ];

  return (
    <div className="div__OutcomesCRUD">
      <Form>
        <Form.Group id="label_first_row">
          <Form.Field width={6}>
            <label>CONCEPTO</label>
          </Form.Field>
          <Form.Field width={10}>
            <label>EMISOR</label>
          </Form.Field>
          <Form.Field width={4}>
            <label>FACTURAS</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>CUENTA</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>DIVISA</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>MONTO</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>T.C.</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>MXN</label>
          </Form.Field>
          <Form.Field width={3}>
            <label>FECHA</label>
          </Form.Field>
          <Form.Field width={1}>
            <label>DEL</label>
          </Form.Field>
        </Form.Group>
        {map(Object.keys(detailsExtraOutcomes), ((index) => (
          <Form.Group key={"ExtraOutcomesCRUD" + String(index)}>
            <Form.Field width={6}>
              <Input placeholder='Concepto' name="CONCEPTO" value={detailsExtraOutcomes[index]["CONCEPTO"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} />
            </Form.Field>
            <Form.Field width={10}>
              <Input placeholder='Receptor' name="RECEPTOR" value={detailsExtraOutcomes[index]["RECEPTOR"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} />
            </Form.Field>
            <Form.Field width={4}>
              <Input placeholder='Facturas' name="FACTURAS" value={detailsExtraOutcomes[index]["FACTURAS"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} />
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown placeholder='Cuenta' name="CUENTA" search selection fluid value={detailsExtraOutcomes[index]["CUENTA"] || ""} options={arrCuentas} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} />
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown placeholder='Divisa' name="DIVISA" search selection fluid value={detailsExtraOutcomes[index]["DIVISA"] || ""} options={arrDivisas} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} />
            </Form.Field>
            <Form.Field width={3}>
              <Input placeholder='Monto' name="MONTO" value={detailsExtraOutcomes[index]["MONTO"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} id="money" />
            </Form.Field>
            <Form.Field width={2}>
              <Input placeholder='T.C.' name="TC" value={detailsExtraOutcomes[index]["TC"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} id="money" />
            </Form.Field>
            <Form.Field width={3}>
              <Input placeholder='MXN' name="MXN" value={detailsExtraOutcomes[index]["MXN"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} id="money" />
            </Form.Field>
            <Form.Field width={3}>
              <Input placeholder='Fecha' name="FECHA" value={detailsExtraOutcomes[index]["FECHA"] || ""} onChange={(e, { name, value }) => { changeDetailsExtraOutcomes(index, name, value) }} id="date" />
            </Form.Field>
            <Form.Field width={1}>
              <Button icon color='red' onClick={() => { openModal(index) }}> <Icon name="delete" /> </Button>
            </Form.Field>
          </Form.Group>
        )))}
        <Button icon size='small' color='orange'> <Icon name="save" /> Guardar </Button>
        <Button floated='right' icon size='small' color="blue"> <Icon name='add' /> Agregar </Button>
      </Form>
      <AcceptModal
        show={showModal}
        onClose={closeModal}
      />
    </div>
  );
}