import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDS4UHEhv8BKRSX4mCUtXud0SwvSYR5PBs",
  authDomain: "wallabytics.firebaseapp.com",
  projectId: "wallabytics",
  storageBucket: "wallabytics.appspot.com",
  messagingSenderId: "462327568905",
  appId: "1:462327568905:web:55fbb06be4f6f6b41c36d8"
};

export const initFirebase = initializeApp(firebaseConfig);

export const db = getFirestore(initFirebase);

export async function isUserAdmin(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    return docSnap.data();
  }
  // console.log("No such user!");
  return { name: "No User", type: null }
}

export function formatLeftNumber(n) {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function formatRightNumber(n, d) {
  if (n.length < d) {
    return n + "0".repeat(d - n.length)
  } else {
    return n.substring(0, d);
  }
}

export function obtainUSDTCStr(input_val) {
  // TODO: Arreglar porque no redondea, solo corta a 4 dígitos
  if (input_val.split('.').length < 3) {
    if (0 <= input_val.indexOf(".")) {
      let decimal_pos = input_val.indexOf(".");
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos + 1);

      // add commas to left side of number
      left_side = formatLeftNumber(left_side);
      right_side = formatRightNumber(right_side, 4);

      return left_side + "." + right_side;
    } else if (input_val.length === 0) {
      return "1.0000"
    } else {
      input_val = formatLeftNumber(input_val);
      return input_val + ".0000"
    }
  } else {
    return input_val
  }
}

export function obtainStr(input_val) {
  // TODO: Arreglar porque no redondea, solo corta a 2 dígitos
  if (input_val.split('.').length < 3) {
    if (0 <= input_val.indexOf(".")) {
      let decimal_pos = input_val.indexOf(".");
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos + 1);
      // add commas to left side of number
      let aux = ''
      if (left_side[0] === '-') {
        aux = '-'
      }
      left_side = aux + formatLeftNumber(left_side);
      right_side = formatRightNumber(right_side, 2);

      return left_side + "." + right_side;
    } else if (input_val.length === 0) {
      return "0.00"
    } else {
      input_val = formatLeftNumber(input_val);
      return input_val + ".00"
    }
  } else {
    return input_val
  }
}

export function obtainFloat(input_val) {
  let aux = ""
  for (let i = 0; i < input_val.length; i++) {
    if (input_val[i] in "0123456789.-") {
      aux += input_val[i]
    }
  }

  if (aux.split('.').length < 3) {
    if (0 <= aux.indexOf(".")) {
      let decimal_pos = aux.indexOf(".");
      let left_side = parseInt(aux.substring(0, decimal_pos).replace(",", ""));
      let right_side = aux.substring(decimal_pos);

      return left_side + parseInt(right_side) * 1.0 / 10 ** right_side.length;
    } else if (aux.length === 0) {
      return 0
    } else {
      aux = formatLeftNumber(aux);
      return parseInt(aux) + 0.0
    }
  } else {
    return 0
  }
}