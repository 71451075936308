import React, { useState, useEffect } from "react";
import { Divider, Header, Icon, Dropdown } from "semantic-ui-react";
import { IncomesCRUD, IncomesR } from "../../components/Incomes";
import { getDatabase, ref, onValue } from "firebase/database";
import { obtainStr, obtainUSDTCStr } from "../../utils/firebase";
import { map } from 'lodash'; // Is only in for arrays
import "./Incomes.scss";

const db = getDatabase();
const optionsData = ref(db, '/options/referencias');

export function Incomes(props) {
  const { viewForm } = props;
  const [optionSel, setOptionSel] = useState("")
  const [yearSel, setYearSel] = useState("");
  const [baseSearch, setBaseSearch] = useState({});
  const [yearSearch, setYearSearch] = useState([]);
  const [currYearSearch, setCurrYearSearch] = useState([]);
  const [detailsExtraIncomes, setDetailsExtraIncomes] = useState([]);

  useEffect(() => {
    onValue(optionsData, (snapshot) => {
      const currObj = snapshot.val();

      const years = [];
      const arrYearSearch = [];
      const objReferencesSearch = {};

      map(Object.keys(currObj), ((year) => {
        years.push(parseInt(year));
        arrYearSearch.push({ key: year, value: year, text: year });
        objReferencesSearch[year] = [];
        map(currObj[year], ((rf) => {
          objReferencesSearch[year].push(rf);
        }))
      }))
      setYearSearch(arrYearSearch); // Anios de busqueda
      setBaseSearch(objReferencesSearch); // Todas las referencias por anio
    })
  }, [])

  const yearOnChange = ((value) => {
    const optionsData = ref(db, '/inextra/' + value);
    onValue(optionsData, (snapshot) => {
      const currObj = snapshot.val();
      const auxIncomes = [];
      for (let i = 0; i < currObj.length; i++) {
        auxIncomes.push(Object.assign({}, currObj[i]))
        auxIncomes[i]["MONTO"] = obtainStr(String(auxIncomes[i]["MONTO"]));
        auxIncomes[i]["TC"] = obtainUSDTCStr(String(auxIncomes[i]["TC"]));
        auxIncomes[i]["MXN"] = obtainStr(String(auxIncomes[i]["MXN"]));
      }
      setDetailsExtraIncomes(auxIncomes);
    })
    setYearSel(value);
  });

  return (
    <div className="div__Incomes">
      <h1>Ingresos extras</h1>
      <Dropdown placeholder='Año' name="year" fluid search selection value={yearSel} options={yearSearch} noResultsMessage='No opción' color='black' onChange={(e, { value }) => { yearOnChange(value) }} />
      <Divider horizontal><Header as='h4'><Icon name='add' />Ingresos</Header></Divider>
      {(viewForm === 4 || viewForm === 2) && <>
        <IncomesCRUD yearSel={yearSel} detailsExtraIncomes={detailsExtraIncomes} setDetailsExtraIncomes={setDetailsExtraIncomes} />
      </>}
      {(viewForm === 3) && <>
        <IncomesR yearSel={yearSel} detailsExtraIncomes={detailsExtraIncomes} setDetailsExtraIncomes={setDetailsExtraIncomes} />
      </>}
    </div>
  );
}