import React, { useState } from "react";
import { Dropdown, Confirm, Button, Table, Divider, Header, Icon, Form, Input, Grid } from "semantic-ui-react";
import { AcceptModal } from "../../Shared";
import { map, isEqual } from 'lodash';
import AcceptForm from "../../Shared/AcceptForm";
import "./BudgetCRUD.scss";

export function BudgetCRUD(props) {
  const { infoBudget, setInfoBudget, detailsBudget, setDetailsBudget } = props;

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleChangeInfo = ((name, value) => {
    setInfoBudget({
      ...infoBudget,
      [name]: value
    })
  })

  const handleChangeDetails = ((name, value) => {
    setDetailsBudget([
    ])
  })

  const saludar = (e) => {
    console.log('hola');
  }

  return (
    <div className="div__BudgetCRUD">
      <Form>
        <div className="div__BudgetDetails">
          <Grid columns={3} divided id="colm">
            <Grid.Row>
              <Grid.Column>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>ATENCION :</label>
                    <Input id='input_budget'
                      name="ATENCION"
                      placeholder='Atención'
                      value={infoBudget.ATENCION || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>VALOR MERCANCIA :</label>
                    <Input id='input_budget'
                      name="VALOR_MERCANCIA"
                      placeholder='Valor de la mercancia'
                      value={infoBudget.VALOR_MERCANCIA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>DIVISA :</label>
                    <Input id='input_budget'
                      name="DIVISA"
                      placeholder='Divisa'
                      value={infoBudget.DIVISA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>T.C. USD :</label>
                    <Input id='input_budget'
                      name="TC_USD"
                      placeholder='T.C. USD'
                      value={infoBudget.TC_USD || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>F.M.E. :</label>
                    <Input id='input_budget'
                      name="FME"
                      placeholder='F.M.E.'
                      value={infoBudget.FME || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>VALOR USD:</label>
                    <Input id='input_budget'
                      name="VALOR_USD"
                      placeholder='Valor USD'
                      value={infoBudget.VALOR_USD || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>TOTAL VALOR USD:</label>
                    <Input id='input_budget'
                      name="TOTAL_USD"
                      placeholder='Total valor USD'
                      value={infoBudget.TOTAL_USD || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>VALOR EN ADUANA:</label>
                    <Input id='input_budget'
                      name="VALOR_ADUANA"
                      placeholder='Valor en Aduana'
                      value={infoBudget.VALOR_ADUANA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>


              <Grid.Column>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>FECHA :</label>
                    <Input id='input_budget'
                      name="FECHA"
                      placeholder='Fecha'
                      value={infoBudget.FECHA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>ARRIBO :</label>
                    <Input id='input_budget'
                      name="ARRIBO"
                      placeholder='Fecha arribo'
                      value={infoBudget.ARRIBO || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>MBL/MAWB :</label>
                    <Input id='input_budget'
                      name="MBL_MAWB"
                      placeholder='MBL/MAWB'
                      value={infoBudget.MBL_MAWB || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>FRACCION :</label>
                    <Input id='input_budget'
                      name="FRACCION"
                      placeholder='Fracción'
                      value={infoBudget.FRACCION || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>PESO :</label>
                    <Input id='input_budget'
                      name="PESO"
                      placeholder='Peso'
                      value={infoBudget.PESO || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>BULTOS :</label>
                    <Input id='input_budget'
                      name="BULTOS"
                      placeholder='Bultos'
                      value={infoBudget.BULTOS || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>MERCANCIA :</label>
                    <Input id='input_budget'
                      name="MERCANCIA"
                      placeholder='Mercancía'
                      value={infoBudget.MERCANCIA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>PROVEEDOR :</label>
                    <Input id='input_budget'
                      name="PROVEEDOR"
                      placeholder='Proveedor'
                      value={infoBudget.PROVEEDOR || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>


              <Grid.Column>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>NUMERO PEDIMENTO :</label>
                    <Input id='input_budget'
                      name="NUMERO_PEDIMENTO"
                      placeholder='Número de pedimento'
                      value={infoBudget.NUMERO_PEDIMENTO || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>NUMERO FACTURA (S) :</label>
                    <Input id='input_budget'
                      name="NUMERO_FACTURA"
                      placeholder='Número de factura'
                      value={infoBudget.NUMERO_FACTURA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>IGI :</label>
                    <Input id='input_budget'
                      name="IGI"
                      placeholder='IGI'
                      value={infoBudget.IGI || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>DTA :</label>
                    <Input id='input_budget'
                      name="DTA"
                      placeholder='DTA'
                      value={infoBudget.DTA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>I.V.A. :</label>
                    <Input id='input_budget'
                      name="IVA"
                      placeholder='I.V.A.'
                      value={infoBudget.IVA || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>PREVALIDACION :</label>
                    <Input id='input_budget'
                      name="PREVALIDACION"
                      placeholder='Prevalidación'
                      value={infoBudget.PREVALIDACION || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>CUOTA COMP. :</label>
                    <Input id='input_budget'
                      name="CUOTA_COMP"
                      placeholder='Cuota compuesta'
                      value={infoBudget.CUOTA_COMP || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline>
                  <Form.Field id="field_budget">
                    <label>TOTAL IMPUESTOS :</label>
                    <Input id='input_budget'
                      name="TOTAL_IMPUESTOS"
                      placeholder='Total de impuestos'
                      value={infoBudget.TOTAL_IMPUESTOS || ""}
                      onChange={(e, { name, value }) => { handleChangeInfo(name, value) }}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <Divider horizontal>
          <Header as='h5'>
            Gastos
          </Header>
        </Divider>

        <Form.Group id="label_first_row">
          <Form.Field width={8}>
            <label>CONCEPTO</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>PRECIO USD</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>PRECIO MXN</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>IMPUESTOS</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>PRESUPUESTO</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>GASTADO</label>
          </Form.Field>
          <Form.Field width={2}>
            <label>POR PAGAR</label>
          </Form.Field>
          <Form.Field width={1}>
            <label>DEL</label>
          </Form.Field>
        </Form.Group>
        {map(detailsBudget, ((index) => (
          <Form.Group key={"detailsBudget_" + String(index)}>
            <Form.Field key={"detailsBudget__Concepto_" + String(index)} width={8}>
              <Input
                name="CONCEPTO"
                placeholder='Concepto'
                value={detailsBudget[index]["CONCEPTO"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__USD_" + String(index)} width={2}>
              <Input
                name="PRECIO_USD"
                placeholder='Precio USD'
                value={detailsBudget[index]["PRECIO_USD"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__MXN_" + String(index)} width={2}>
              <Input
                name="PRECIO_MXN"
                placeholder='Precio MXN'
                value={detailsBudget[index]["PRECIO_MXN"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__Impuestos_" + String(index)} width={2}>
              <Input
                name="IMPUESTOS"
                placeholder='Impuestos'
                value={detailsBudget[index]["IMPUESTOS"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__Presupuesto_" + String(index)} width={2}>
              <Input
                name="PRESUPUESTO"
                placeholder='Presupuesto'
                value={detailsBudget[index]["PRESUPUESTO"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__Gastado_" + String(index)} width={2}>
              <Input
                name="GASTADO"
                placeholder='Gastado'
                value={detailsBudget[index]["GASTADO"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__Pagar_" + String(index)} width={2}>
              <Input
                name="POR_PAGAR"
                placeholder='Por pagar'
                value={detailsBudget[index]["POR_PAGAR"] || ""}
                onChange={(e, { name, value }) => { handleChangeDetails(name, value) }}
              />
            </Form.Field>
            <Form.Field key={"detailsBudget__Borrar_" + String(index)} width={1}>
              <Button icon color='red' onClick={openModal}> <Icon name="trash" /> </Button>
            </Form.Field>
          </Form.Group>
        )))}

        <Button icon size='small' color='orange'> <Icon name="save" /> Guardar</Button>
        <Button icon size='small' color='teal'> <Icon name="print" /> Presupuesto</Button>
        <Button icon size='small' color="teal"> <Icon name="print" /> Comprobación de Gastos</Button>
        <Button floated='right' icon size='small' color="blue" onClick={() => saludar()}> <Icon name='add' /> Agregar </Button>
      </Form>

      <AcceptModal
        show={showModal}
        onClose={closeModal}
      />
    </div >
  );
}