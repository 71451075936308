import React, { useState } from 'react';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword } from "firebase/auth";
import { ToastContainer } from 'react-toastify';
import { Auth } from "./pages";
import { PagesRouter } from "./pagesrouter";

export default function App() {
  const auth = getAuth();
  const [user, setUser] = useState(undefined);
  const [reloadApp, setReloadApp] = useState(false);

  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  if (reloadApp);

  if (user === undefined) return null;

  return user ? (
    <PagesRouter user={user} />
  ) : (
    <Auth />
  );
}

// {/* <Dentro /> */}
// {/* // {!user ? <Auth /> : <Logged user={user} setReloadApp={setReloadApp} />} */}
