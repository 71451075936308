import React, { useState } from 'react';
import { Divider, Header, Icon, Dropdown } from "semantic-ui-react";
import { getDatabase, ref, onValue } from "firebase/database";
import { GraphBalance } from "../../components/GlobalBalance";
import { map } from 'lodash'; // Is only in for arrays
import "./GlobalBalance.scss";

const db = getDatabase();

export function GlobalBalance(props) {
  const { dataOptions, dropdownOptions } = props;
  const [infoGraphLine, setInfoGraphLine] = useState([]);
  const [infoGraphZero, setInfoGraphZero] = useState([]);

  const yearOnChange = ((value) => {
    const vals = {};
    map(dataOptions["referencias"][value], ((rf) => {
      onValue(ref(db, '/operations/' + rf + "/info/CONCLUIDA/"), (snapshot) => {
        if (snapshot.val() === true) {
          onValue(ref(db, '/operations/' + rf + "/details/0/ENTREGA"), (snapshot) => {
            let fecha = snapshot.val();
            if (fecha !== "") {
              onValue(ref(db, '/operations/' + rf + "/BALANCE/"), (snapshot) => {
                let mxn = snapshot.val();
                vals[fecha] = vals[fecha] + mxn || mxn;
              })
            }
          })
        }
      })
    }))
    let suma = 0;
    const ord = Object.keys(vals).sort();
    const values = [];
    map(ord, ((fecha) => {
      suma += vals[fecha]
      values.push({ x: fecha, y: suma });
    }))
    setInfoGraphLine(values);
    setInfoGraphZero([{ x: ord[0], y: 0 }, { x: ord[ord.length - 1], y: 0 }]);
  })

  return (
    <div className="div__GlobalBalance">
      <h1>Gráfica de balance general</h1>
      <Dropdown placeholder='Año' name="year" fluid search selection options={dropdownOptions["years"]} noResultsMessage='No opción' color='black' onChange={(e, { value }) => { yearOnChange(value) }} />
      <Divider horizontal><Header as='h4'><Icon name='line graph' /> Gráfica</Header></Divider>
      <GraphBalance infoGraphLine={infoGraphLine} infoGraphZero={infoGraphZero} />
    </div>
  )
}