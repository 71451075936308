import React, { useState } from "react";
import { Divider, Header, Table, Icon, Dropdown, Form } from "semantic-ui-react";
import { getDatabase, ref, onValue } from "firebase/database";
import { obtainStr } from "../../utils/firebase";
import { map } from 'lodash'; // Is only in for arrays
import "./Operations.scss";

const db = getDatabase();

export function Operations(props) {
  const { viewForm, dataOptions, dropdownOptions } = props;
  const [selectedYear, setSelectedYear] = useState(currentYear());
  const [currYearSearch, setCurrYearSearch] = useState([]);

  const yearOnChange = (() => {
    const arrCurrYearSearch = [];
    map(dataOptions["referencias"][selectedYear], ((rf) => {
      let row = {}
      row["REFERENCE"] = rf;
      onValue(ref(db, '/operations/' + rf + "/info/CLIENTE/"), (snapshot) => { row["CLIENTE"] = snapshot.val() !== "" ? snapshot.val() : "" })
      onValue(ref(db, '/operations/' + rf + "/info/OPERACION/"), (snapshot) => { row["TIPOPERACION"] = snapshot.val() !== "" ? snapshot.val() : "" })
      onValue(ref(db, '/operations/' + rf + "/info/ORIGEN/"), (snapshot) => { row["ORIGEN"] = snapshot.val() !== "" ? snapshot.val() : "" })
      onValue(ref(db, '/operations/' + rf + "/info/DESTINO/"), (snapshot) => { row["DESTINO"] = snapshot.val() !== "" ? snapshot.val() : "" })
      onValue(ref(db, '/operations/' + rf + "/info/ADUANA/"), (snapshot) => { row["ADUANA"] = snapshot.val() !== "" ? snapshot.val() : "" })
      onValue(ref(db, '/operations/' + rf + "/info/CONCLUIDA/"), (snapshot) => { row["CONCLUIDA"] = snapshot.val() === true ? "SI" : "NO" })
      onValue(ref(db, '/operations/' + rf + "/BALANCE/"), (snapshot) => { row["BALANCE"] = snapshot.val() !== "" ? snapshot.val() : "" })
      onValue(ref(db, '/operations/' + rf + "/details/0/ENTREGA"), (snapshot) => { row["ENTREGA"] = snapshot.val() !== "" ? snapshot.val() : "" })
      arrCurrYearSearch.push(row);
    }))
    setCurrYearSearch(arrCurrYearSearch);
  })

  return (
    <div className="div__Operations">
      <h1>Búsqueda por tabla</h1>
      <div className="year_search_bar">
        <Dropdown placeholder='Año' name="year" fluid search selection value={selectedYear} options={dropdownOptions['years']} noResultsMessage='No opción' color='black' onChange={(e, { value }) => { setSelectedYear(value); }} />
        <Form.Button icon={<Icon name='search' inverted />} onClick={() => { yearOnChange(); }} />
      </div>
      <Divider horizontal><Header as='h4'><Icon name='table' />Operaciones</Header></Divider>
      <Table celled inverted selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Referencia</Table.HeaderCell>
            <Table.HeaderCell width={3}>Cliente</Table.HeaderCell>
            <Table.HeaderCell width={3}>Tipo de Operación</Table.HeaderCell>
            <Table.HeaderCell width={2}>Origen</Table.HeaderCell>
            <Table.HeaderCell width={2}>Destino</Table.HeaderCell>
            <Table.HeaderCell width={2}>Aduana</Table.HeaderCell>
            <Table.HeaderCell width={2}>Entrega</Table.HeaderCell>
            {(viewForm === 4 || viewForm === 3) && <>
              <Table.HeaderCell width={1}>Concluida</Table.HeaderCell>
              <Table.HeaderCell width={1}>Balance</Table.HeaderCell>
            </>}
          </Table.Row>
        </Table.Header>
        {/* <Table.Cell selectable>
          <a href='#'>Edit</a>
        </Table.Cell> */}
        <Table.Body>
          {map(Object.keys(currYearSearch), ((index) => (<>
            <Table.Row key={"Operations_" + String(index)}>
              <Table.Cell key={"Operations_Referencia_" + String(index)}>{currYearSearch[index]["REFERENCE"] || ""}</Table.Cell>
              <Table.Cell key={"Operations_Cliente_" + String(index)}>{currYearSearch[index]["CLIENTE"] || ""}</Table.Cell>
              <Table.Cell key={"Operations_Operacion_" + String(index)}>{currYearSearch[index]["TIPOPERACION"] || ""}</Table.Cell>
              <Table.Cell key={"Operations_Origen_" + String(index)}>{currYearSearch[index]["ORIGEN"] || ""}</Table.Cell>
              <Table.Cell key={"Operations_Destino_" + String(index)}>{currYearSearch[index]["DESTINO"] || ""}</Table.Cell>
              <Table.Cell key={"Operations_Aduana_" + String(index)}>{currYearSearch[index]["ADUANA"] || ""}</Table.Cell>
              {(viewForm === 4 || viewForm === 3) ? <>
                {(currYearSearch[index]["CONCLUIDA"] === "SI" && (currYearSearch[index]["ENTREGA"] === "" || currYearSearch[index]["ENTREGA"] === null)) ?
                  <><Table.Cell key={"Operations_Entrega_" + String(index)} negative>{currYearSearch[index]["ENTREGA"] || ""}</Table.Cell></> :
                  <><Table.Cell key={"Operations_Entrega_" + String(index)}>{currYearSearch[index]["ENTREGA"] || ""}</Table.Cell></>
                }
              </> : <>
                <Table.Cell key={"Operations_Entrega_" + String(index)}>{currYearSearch[index]["ENTREGA"] || ""}</Table.Cell>
              </>}
              {(viewForm === 4 || viewForm === 3) && <>
                <Table.Cell key={"Operations_Concluida_" + String(index)}>{currYearSearch[index]["CONCLUIDA"] || ""}</Table.Cell>
                {(0 <= currYearSearch[index]["BALANCE"]) ?
                  <><Table.Cell key={"Operations_Balance_" + String(index)} textAlign='right' positive>{obtainStr(String(currYearSearch[index]["BALANCE"])) || ""}</Table.Cell></> :
                  <><Table.Cell key={"Operations_Balance_" + String(index)} textAlign='right' negative>{obtainStr(String(currYearSearch[index]["BALANCE"])) || ""}</Table.Cell></>
                }
              </>}
            </Table.Row>
          </>)))}
        </Table.Body>
      </Table>
    </div >
  );
}

function currentYear() {
  const currYear = new Date().getFullYear();
  return currYear;
}
