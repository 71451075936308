import React from "react";
import { Table } from "semantic-ui-react";
import { map } from 'lodash';

import "./OutcomesR.scss";

export function OutcomesR(props) {
  const { detailsOutcomes } = props;

  return (
    <div className="div__OutcomesR">
      <Table celled inverted selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Concepto</Table.HeaderCell>
            <Table.HeaderCell width={3}>Receptor</Table.HeaderCell>
            <Table.HeaderCell width={2}>Facturas</Table.HeaderCell>
            <Table.HeaderCell width={1}>Cuenta</Table.HeaderCell>
            <Table.HeaderCell width={1}>Divisa</Table.HeaderCell>
            <Table.HeaderCell width={1}>Monto</Table.HeaderCell>
            <Table.HeaderCell width={1}>T.C.</Table.HeaderCell>
            <Table.HeaderCell width={1}>MXN</Table.HeaderCell>
            <Table.HeaderCell width={1}>FECHA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(Object.keys(detailsOutcomes), ((index) => (<>
            <Table.Row key={"OutcomesR_" + String(index)}>
              <Table.Cell key={"OutcomesR_Concepto_" + String(index)}>{detailsOutcomes[index]["CONCEPTO"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_Receptor_" + String(index)}>{detailsOutcomes[index]["RECEPTOR"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_Facturas_" + String(index)}>{detailsOutcomes[index]["FACTURAS"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_Cuenta_" + String(index)}>{detailsOutcomes[index]["CUENTA"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_Divisa_" + String(index)}>{detailsOutcomes[index]["DIVISA"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_Monto_" + String(index)} textAlign='right'>{detailsOutcomes[index]["MONTO"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_TC_" + String(index)} textAlign='right'>{detailsOutcomes[index]["TC"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_MXN_" + String(index)} textAlign='right'>{detailsOutcomes[index]["MXN"] || ""}</Table.Cell>
              <Table.Cell key={"OutcomesR_Fecha_" + String(index)} textAlign='right'>{detailsOutcomes[index]["FECHA"] || ""}</Table.Cell>
            </Table.Row>
          </>)))}
        </Table.Body>
      </Table>
    </div>
  );
}