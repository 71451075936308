import React, { useEffect, useState } from "react";
import { Dropdown, Icon, Form } from "semantic-ui-react";
import "./RefSearch.scss";

export function RefSearch(props) {
  const { setSelectedReference, dropdownOptions } = props;

  const [selectedYear, setSelectedYear] = useState(currentYear());
  const [currReference, setCurrReference] = useState("");
  const [referenceSearch, setReferenceSearch] = useState([]);

  useEffect(() => {
    setReferenceSearch(dropdownOptions['referencias'][currentYear()]);
  }, [dropdownOptions]);

  return (
    <div className="div__RefSearch">
      <Dropdown placeholder='Año' name="year" search selection value={selectedYear} options={dropdownOptions['years']} noResultsMessage='No referencia' color='black' onChange={(e, { value }) => { setSelectedYear(value); setReferenceSearch(dropdownOptions['referencias'][value]); }} />
      <Dropdown placeholder='Buscar' name="reference" fluid search selection value={currReference} options={referenceSearch} noResultsMessage='No referencia' color='black' onChange={(e, { value }) => { setCurrReference(value); }} />
      <Form.Button icon={<Icon name='search' inverted />} onClick={() => { setSelectedReference(currReference); }} />
    </div>
  );
}

function currentYear() {
  const currYear = new Date().getFullYear();
  return currYear;
}
