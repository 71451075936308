import React, { useState, useCallback } from "react";
import { Form, Image } from "semantic-ui-react";
// import { useDropzone } from "react-dropzone";
// import { useFormik } from "formik";
// import classNames from "classnames";
// import { v4 as uuidv4 } from "uuid";
import { Storage, Artist } from "../../../api";
import { noImage } from "../../../assets";
import "./NewOutcomeForm.scss";

const storageController = new Storage();
const artistController = new Artist();

export function NewOutcomeForm(props) {
  const { onClose } = props;
  const [image, setImage] = useState(null);

  return (
    <Form className="new-artist-form">

      <Form.Input
        name="name"
        placeholder="Nombre del artista"
      />

      <Form.Button type="submit" primary fluid loading={false}>
        Crear artista
      </Form.Button>
    </Form>
  );
}